*{
  padding: 0;
  margin: 0;
}

#home{
 height: 100vh;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
 
 
}
.home-img{
  height: 350px;
  border-radius: 40px;
}
.pass{
  width: 50%;
}
.Home{
  margin-top: 90px;
}
#login-home{
  height: 100%;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
}

.page{
  height: 300px;
  width: 300px;
  box-shadow: 0 5px 13px rgb(0 0 0 / 25%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width:480px){
  .p{
    margin-left: 20px;
  }
  
}
@media screen and (min-width:999px){
  .pages-row{
    margin-top: 104px;
  }
}

.up-add-row {
  margin-top: 100px;
}

.peroperty-img{
  height: 250px;
}
.nav-link{
color: blue !important;
}
.page{
  background-color: red;
  border-radius: 30px;
 
}
.link-page{
  text-decoration: none;
  color: #fff !important;
  
}
.last-page{
  margin-top: 10px;
}
/* #hero-img{
  height: 200px;
}
#footer-img{
  height: 200px;
} */